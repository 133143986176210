import { config } from "@/config/config";
import Axios, { AxiosInstance } from "axios";

class ApiClass {
  private static instance: ApiClass;
  public axios: AxiosInstance;

  constructor() {
    this.axios = Axios.create();
    this.axios.defaults.headers.common["domainId"] = config.api.domaindId;
  }

  public static getInstance(): ApiClass {
    if (!ApiClass.instance) {
      ApiClass.instance = new ApiClass();
    }
    return ApiClass.instance;
  }
}

const Api = ApiClass.getInstance();

export { Api, ApiClass };
