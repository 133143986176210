export const config = {
  openPrefixApp: process.env.OPEN_PREFIX_APP as string,
  api: {
    domaindId: "HNACklrCkE6lv3eRZtvdUA==",
    endPoint: {
      content: process.env.API_CONTENT as string,
      log: process.env.API_LOG as string,
      web: process.env.API_WEB as string,
    },
  },
};
