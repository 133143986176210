"use client";
import { WebApi } from "@/api/web";
import { useFormik } from "formik";
import * as Yup from "yup";

export default function Contact() {
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Informe o nome"),
      email: Yup.string().email("Informe um e-mail válido.").required("Informe o e-mail."),
      phone: Yup.string().required("Informe o telefone"),
      subject: Yup.string().required("Informe o assunto."),
      message: Yup.string().required("Informe a mensagem.").max(100, "A mensagem pode conter no máximo 100 caracteres."),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      WebApi.contact(values)
        .then((response) => {
          formik.resetForm();
          formik.setStatus({ message: "Mensagem enviada com sucesso" });
        })
        .catch(({ response }) => {
          formik.setStatus({ message: "Ops! Tivemos algum problema" });
        });
      setTimeout(() => {
        formik.setStatus(undefined);
      }, 4000);

      formik.setSubmitting(false);
    },
  });

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pb-20">
          <div className="relative bg-gray-900 rounded py-10 px-8 md:py-16 md:px-12 shadow-2xl overflow-hidden" data-aos="zoom-y-out">
            <div className="absolute right-0 bottom-0 pointer-events-none hidden lg:block" aria-hidden="true">
              <svg width="428" height="328" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <radialGradient cx="35.542%" cy="34.553%" fx="35.542%" fy="34.553%" r="96.031%" id="ni-a">
                    <stop stopColor="#DFDFDF" offset="0%" />
                    <stop stopColor="#4C4C4C" offset="44.317%" />
                    <stop stopColor="#333" offset="100%" />
                  </radialGradient>
                </defs>
                <g fill="none" fillRule="evenodd">
                  <g fill="#FFF">
                    <ellipse fillOpacity=".04" cx="185" cy="15.576" rx="16" ry="15.576" />
                    <ellipse fillOpacity=".24" cx="100" cy="68.402" rx="24" ry="23.364" />
                    <ellipse fillOpacity=".12" cx="29" cy="251.231" rx="29" ry="28.231" />
                    <ellipse fillOpacity=".64" cx="29" cy="251.231" rx="8" ry="7.788" />
                    <ellipse fillOpacity=".12" cx="342" cy="31.303" rx="8" ry="7.788" />
                    <ellipse fillOpacity=".48" cx="62" cy="126.811" rx="2" ry="1.947" />
                    <ellipse fillOpacity=".12" cx="78" cy="7.072" rx="2" ry="1.947" />
                    <ellipse fillOpacity=".64" cx="185" cy="15.576" rx="6" ry="5.841" />
                  </g>
                  <circle fill="url(#ni-a)" cx="276" cy="237" r="200" />
                </g>
              </svg>
            </div>

            <div className="relative flex flex-col lg:flex-row justify-between items-center">
              {/* CTA content */}
              <div className="text-center lg:text-left lg:max-w-xl">
                <h3 className="h3 text-white mb-2">Ficou com dúvidas?</h3>
                <p className="text-gray-300 text-lg mb-6">Mande sua mensagem que falaremos com você.</p>

                <form className="flex flex-col w-full gap-2" onSubmit={formik.handleSubmit}>
                  <div className="grid grid-cols-2 gap-2">
                    <div className="flex flex-col">
                      <input type="text" className="form-input w-full appearance-none bg-gray-800 border border-gray-700 focus:border-gray-600 rounded-sm px-4 py-3 mb-2 sm:mb-0 sm:mr-2 text-white placeholder-gray-500" placeholder="Nome" aria-label="Nome" {...formik.getFieldProps("name")} />
                      {formik.errors.name && <div className="mt-1 text-red-600">{formik.errors.name}</div>}
                    </div>
                    <div className="flex flex-col">
                      <input type="email" className="form-input w-full appearance-none bg-gray-800 border border-gray-700 focus:border-gray-600 rounded-sm px-4 py-3 mb-2 sm:mb-0 sm:mr-2 text-white placeholder-gray-500" placeholder="E-mail" aria-label="E-mail" {...formik.getFieldProps("email")} />
                      {formik.errors.email && <div className="mt-1 text-red-600">{formik.errors.email}</div>}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-2">
                    <div className="flex flex-col">
                      <input type="phone" className="form-input w-full appearance-none bg-gray-800 border border-gray-700 focus:border-gray-600 rounded-sm px-4 py-3 mb-2 sm:mb-0 sm:mr-2 text-white placeholder-gray-500" placeholder="Telefone" aria-label="Telefone" {...formik.getFieldProps("phone")} />
                      {formik.errors.phone && <div className="mt-1 text-red-600">{formik.errors.phone}</div>}
                    </div>
                    <div className="flex flex-col">
                      <input type="text" className="form-input w-full appearance-none bg-gray-800 border border-gray-700 focus:border-gray-600 rounded-sm px-4 py-3 mb-2 sm:mb-0 sm:mr-2 text-white placeholder-gray-500" placeholder="Assunto" aria-label="Assunto" {...formik.getFieldProps("subject")} />
                      {formik.errors.subject && <div className="mt-1 text-red-600">{formik.errors.subject}</div>}
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <textarea className="form-input w-full appearance-none bg-gray-800 border border-gray-700 focus:border-gray-600 rounded-sm px-4 py-3 mb-2  text-white placeholder-gray-500" placeholder="Diga algo…" aria-label="Diga algo…" {...formik.getFieldProps("message")} />
                    {formik.errors.message && <div className="mt-1 text-red-600">{formik.errors.message}</div>}
                  </div>
                  {formik.status && (
                    <div className="flex items-center text-lg p-5 rounded border mb-3 bg-white shadow-md border-gray-200 hover:shadow-lg">
                      <div className="font-bold leading-snug tracking-tight mb-1">{formik.status.message}</div>
                    </div>
                  )}
                  <button type="submit" disabled={formik.isSubmitting} className="btn text-white bg-blue-600 hover:bg-blue-700 shadow">
                    {formik.isSubmitting ? "Enviando..." : "Enviar"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
