"use client";

export default function Features() {
  return (
    <section className="relative">
      <div className="absolute inset-0 bg-gray-100 pointer-events-none mb-16" aria-hidden="true"></div>
      <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-12 md:pt-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h1 className="h2 mb-4">Explore todas as funcionalidades</h1>
            <p className="text-xl text-gray-600">A plataforma Cidios tem funcionalidades que permitem a integração e participação colaborativa dos cidadãos, para que juntos busquem melhorias à comunidade local.</p>
          </div>

          {/* Section content */}
          <div className="md:grid md:grid-cols-2 md:gap-4">
            {/* Content */}

            <div className="flex items-center text-lg p-5 rounded border mb-3 bg-white shadow-md border-gray-200 hover:shadow-lg">
              <div>
                <div className="font-bold leading-snug tracking-tight mb-1">Compartilhe</div>
                <div className="text-gray-600">Compartilhe notícias locais, eventos emocionantes e descobertas interessantes sobre a cidade com amigos e vizinhos.</div>
              </div>
              <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                <svg className="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.953 4.29a.5.5 0 00-.454-.292H6.14L6.984.62A.5.5 0 006.12.173l-6 7a.5.5 0 00.379.825h5.359l-.844 3.38a.5.5 0 00.864.445l6-7a.5.5 0 00.075-.534z" />
                </svg>
              </div>
            </div>
            <div className="flex items-center text-lg p-5 rounded border mb-3 bg-white shadow-md border-gray-200 hover:shadow-lg">
              <div>
                <div className="font-bold leading-snug tracking-tight mb-1">Comentário</div>
                <div className="text-gray-600">Participe de grupos em sua comunidade, expressando opiniões e idéias.</div>
              </div>
              <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                <svg className="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.854.146a.5.5 0 00-.525-.116l-11 4a.5.5 0 00-.015.934l4.8 1.921 1.921 4.8A.5.5 0 007.5 12h.008a.5.5 0 00.462-.329l4-11a.5.5 0 00-.116-.525z" fillRule="nonzero" />
                </svg>
              </div>
            </div>
            <div className="flex items-center text-lg p-5 rounded border mb-3 bg-white shadow-md border-gray-200 hover:shadow-lg">
              <div>
                <div className="font-bold leading-snug tracking-tight mb-1">Fique informado</div>
                <div className="text-gray-600">Esteja sempre um passo à frente ao receber atualizações regulares sobre as atividades e eventos da cidade diretamente na palma da sua mão.</div>
              </div>
              <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                <svg className="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.334 8.06a.5.5 0 00-.421-.237 6.023 6.023 0 01-5.905-6c0-.41.042-.82.125-1.221a.5.5 0 00-.614-.586 6 6 0 106.832 8.529.5.5 0 00-.017-.485z" fill="#191919" fillRule="nonzero" />
                </svg>
              </div>
            </div>
            <div className="flex items-center text-lg p-5 rounded border mb-3 bg-white shadow-md border-gray-200 hover:shadow-lg">
              <div>
                <div className="font-bold leading-snug tracking-tight mb-1">Faça solititações</div>
                <div className="text-gray-600">Faça a diferença na sua comunidade ao enviar solicitações para melhorias na cidade, desde reparos de estradas até problemas de iluminação pública.</div>
              </div>
              <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                <svg className="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.334 8.06a.5.5 0 00-.421-.237 6.023 6.023 0 01-5.905-6c0-.41.042-.82.125-1.221a.5.5 0 00-.614-.586 6 6 0 106.832 8.529.5.5 0 00-.017-.485z" fill="#191919" fillRule="nonzero" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
